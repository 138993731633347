<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <p class="font-medium-5 text-center font-weight-bolder">
      {{ $t('Update Credit Card') }}
    </p>
    <financial-info-form
      v-if="financialInfo.id"
      ref="financial-info-form"
      :is-edit="isEdit"
    />
    <div v-else>
      <div v-if="!error">
        <skeleton />
      </div>
      <div v-else>
        <b-link :to="{ name: 'home-customers-financial-info-list'}">
          {{ $t('Financial Info List') }}
        </b-link>
      </div>
    </div>
  </div>

</template>

<script>

import {
  BLink, VBTooltip, BCol, BRow,
} from 'bootstrap-vue'
import Skeleton from '@/views/components/Skeleton.vue'
import store from '@/store'
import FinancialInfoForm from '../components/Form.vue'
import config from '../config'
import CustomerName from '../../../components/CustomerName.vue'

export default {
  name: 'FinancialInfoUpdate',
  components: {
    FinancialInfoForm,
    Skeleton,
    BLink,
    BCol,
    BRow,
    CustomerName,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['financial-info-form']) this.$refs['financial-info-form'].showConfirmationModal(next)
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    financialInfo() {
      return this.$store.state[this.MODULE_NAME].financialInfoForm
    },
  },
  mounted() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.finInfoId).then(res => {
      const { data } = res.data
      const financialInfo = this.mappingFields(Object.keys(this.fields), data, {
        cardholder_id: data.cardholder,
        billing_address_id: data.billing_address,
        id: data.id,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, financialInfo)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM_CLONE`, financialInfo)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
  },
  methods: {
    isEditAble() {
      // eslint-disable-next-line no-return-assign
      return this.isEdit = !this.isEdit
    },
  },
  setup() {
    const MODULE_NAME = 'financialInfo'
    const { fields } = config()
    return {
      MODULE_NAME,
      fields,
    }
  },
}
</script>

<style>

</style>
